<template>
  <el-drawer
    title="新增"
    :before-close="handleClose"
    :visible.sync="dialog"
    direction="rtl"
    custom-class="demo-drawer"
    ref="drawer"
  >
    <el-transfer
      v-model="dataList"
      @change="handleChange"
      filterable
      :titles="['Source', 'Target']"
      :button-texts="['到左边', '到右边']"
      :data="data"
    ></el-transfer>
    <el-button type="primary" @click="addFormButton()">确 定</el-button>
  </el-drawer>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      data: [
        {
          key: "1",
          label: `备选项 `
        },
        {
          key: "2",
          label: `备选项 `
        }
      ],
      dataList: []
    };
  },
  methods: {
    show(id, type) {
      this.dialog = true;
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.dialog = false;
        })
        .catch(_ => {});
    },
    handleChange(value, direction, movedKeys) {
      console.log("1" + value, movedKeys);
      console.log("2" + direction);
      console.log("3" + this.dataList);
    },
    addFormButton() {
      console.log(this.dataList);
      console.log(this.data);
    }
  }
};
</script>
<style scoped>
</style>