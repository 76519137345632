<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>居家管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片试图 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <span class="demonstration">系统名称：</span>
          <el-input placeholder style="width:60%" v-model="queryFrom.name"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primay" @click="menuListButton()">查询</el-button>
          <el-button type="primay">重置</el-button>
        </el-col>
      </el-row>
      <!-- 按钮区 -->
      <el-row :gutter="20" style="margin:9px 2px">
          <!-- <router-link to="/xunMy-user">所有功能</router-link> -->
        <el-button type="warning" icon="el-icon-add" size="min" >新增</el-button>
      </el-row>
      <!-- 列表区域 -->

      <el-table :data="systemDataList" border stripe>
        <el-table-column label="序号" align="center" width="100px">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="系统名字" prop="name" />
        <el-table-column label="系统编码" prop="code" />
        <el-table-column label="系统英文名字" prop="englishName" />
        <el-table-column label="系统图标" prop="icon" />
        <el-table-column label="系统使用人数" prop="code" />
        <el-table-column label="状态" prop="status" />
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editMenu(scope.$index,scope.row)"
            >修改</el-button>
            <el-button size="mini" type="text" icon="el-icon-edit">删除</el-button>
            <el-button size="mini" type="text" icon="el-icon-edit">详情</el-button>
            <el-button size="mini" type="text" icon="el-icon-edit">所有功能</el-button>
            <el-button size="mini" type="text" icon="el-icon-edit" @click="addUserButton(scope.$index,scope.row)">人员列表</el-button>
            <el-button size="mini" type="text" icon="el-icon-edit">配置角色</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <AddUser ref="addUserModules"></AddUser>
  </div>
</template>

<script>
import AddUser from "./modules/addUser";
import { listproductApi } from "@/api/system/product/product.js";
export default {
  name: "menuList",
  components: {
    AddUser
  },
  data() {
    return {
      radio: null,
      systemDataList: [],
      queryFrom: {
        systemSource: "xunMy"
      }
    };
  },
  created() {
    this.systemListButton();
  },
  methods: {
    systemListButton() {
      listproductApi(this.queryFrom).then(res => {
        console.log(res);
        this.systemDataList = res.result;
      });
    },
    addUserButton(index, row) {
      this.$refs.addUserModules.show(row.id, 2);
    }
  }
};
</script>

<style lang="less" scoped>
</style> 